.MobileNavbar {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.mobileNavLink {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobileUploadOptions {
    position: absolute;
    height: 100%;
    bottom: 0;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    color: white;
    padding-left: 14%;
    padding-right: 14px;
    transition: all 0.3s ease-out;
    transform: translateY(100%);
}

.mobileUploadOptions.open {
    transform: translateY(0);
}

.mobileUploadButton {
    border-radius: 10px;
    background-color: #1a1a1a;
    border: none;
    color: white;
    padding: 7px;
    margin-right: 10px;
}
