.menuLinks {
    display: flex;
    width: 21%;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    height: 90vh;
}

.menuLink {
    display: flex;
    align-items: center;
    width: 80%;
    height: 21px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    color: white;
    gap: 37px;
}

    .menuLink:hover {
        background-color: #2f2d2dcf;
        cursor: pointer;
    }

hr {
    color: white;
    width: 80%;
}    

.menuLinkHeaders {
    color: white;
    display: flex;
    justify-content: flex-start;
    width: 75%;
}

.menuLinks::-webkit-scrollbar {
    width: 10px;
}

.menuLinks::-webkit-scrollbar-track {
    background: transparent;
}

.menuLinks::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    border: transparent; 
}

.menuLinks::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

.infoText {
    color: white;
    width: 80%;
}

.HomePage {
    display: flex;
}

.uploadedVideos {
    padding: 50px;
    display: grid;
    gap: 17px;
    grid-template-columns: 1fr 1fr 1fr;
}

.thumbnail {
    width: 100%;
    height: 200px;
    border-radius: 10px;
}

.videoTitle, .videoUploader {
    text-decoration: none;
    margin: 0;
    color: white;
    
}

.videoUploader {
    color: #a88f8fcf;
    width: fit-content;
}

    .videoUploader:hover {
        color: white;
    }

.videoTitle {
    margin-top: 7px;
}

.promoLink {
    text-decoration: none;
    color: white;
}

.homepageLoading {
    padding: 7%;
}

.mobileNavbar {
    display: none;
}


@media (max-width: 800px) {
    .menuLinks {
        display: none;
    }

    .uploadedVideos {
        display: flex;
        flex-direction: column;
    }

    .mobileNavbar {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 14px;
    }

    .videoPackage {
        width: 100% !important;
    }

    .uploadedVideos {
        width: 100% !important;
        padding: 0;
    }

    .videoTitle {
        width: 100% !important;
        margin-bottom: 0% !important;
    }

    .videoTitleTitle {
        padding-left: 14px;
        margin: 0;
    }

    .videoUploader {
        padding-left: 14px;
        padding-top: 0 !important;
        margin-top: -14px;
    }

    .thumbnail {
        width: 100vw !important;
    }

}