.loginForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.login {
    width: 24%;
    color: white;
}

.SignIn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.formButton {
    cursor: pointer;
}

@media (max-width: 800px) {

    .login {
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
    }
}